:root {
    /*  fall colors */
    /*    --body-bg: #2E3409;
      --header-bg: #E3F08E;
      --card-bg: #F7FBDF;
      --proj-title-bg: #E3F08E;
      --header-text: #2E3409;
      --proj-title-text: #2E3409;
      --body-text: #2E3409;
      --a-text: #2E3409;
  */

    /* triadic, just off 12 o'clock */
    /*    --body-bg: #341109;
      --header-bg: #183BF0;
      --card-bg: #D4DAF7;
      --proj-title-bg: #183BF0;
      --header-text: #E4F132;
      --proj-title-text: #E4F132;
      --body-text: #341109;
      --a-text: #DC5638;
  */

    /* white */
    /*    --body-bg: #fafafa;
      --header-bg: #ffffff;
      --card-bg: #ffffff;
      --proj-title-bg: ;
      --header-text: #183BF0;
      --proj-title-text: #183BF0;
      --body-text: #341109;
      --a-text: #341109;
  */

    /* nordtheme.com */
    --body-bg: #f6f6f6;
    --header-bg: #fafafa;
    --card-bg: #fafafa;
    --proj-title-bg: ;
    --header-text: #2e3440;
    --proj-title-text: #2e3440;
    --body-text: #2e3440;
    --a-text: #2e3440;
}

.card-panel {
    box-shadow: none;
}

body {
    background: var(--body-bg);

    /*    background: repeating-linear-gradient(
          30deg,
          #ffffff,
          #ffffff 10px,
          #ffffff 10px,
          #FD3A0F 2000px
      );
  */
    /*    background: linear-gradient(5deg, #FD3A0F, #E4F132);
      background: linear-gradient(10deg, #FD3A0F, #FFFFFF);
      background: linear-gradient(10deg, #FECDC2, #FFFFFF);
  */
    background-attachment: fixed;
    color: var(--body-text);
    font-family: "Raleway";
}

a {
    color: var(--a-text);
}

.card-panel {
    background: var(--card-bg);
}

.header {
    background: var(--header-bg);
    color: var(--header-text);
    /*    border: 25px dashed var(--header-text); */
}

.project-title {
    background: var(--proj-title-bg);
    color: var(--proj-title-text);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0rem;
}

.project-title h4 {
    margin-top: 0.912rem;
}

.header h1 {
    margin-top: 1.168rem;
}

img {
    margin-top: 0.5rem;
}

ul {
    list-style-type: none;
    /*use padding to move list item from left to right*/
    padding-left: 1em !important;
}

ul li:before {
    content: "-";
    position: absolute;
    /*change margin to move dash around*/
    margin-left: -1em;
}

a,
p,
li {
    font-size: 1.25rem;
}

a {
    text-decoration: underline;
}
